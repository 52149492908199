<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">

    <div>

      <div class="d-flex justify-content-end">

        <create-bank-account
          v-if="bankAccountModal"
          :edit="edit"
          :items="dataBankAccount"
          @close="closeBankAccountModal"
          @refresh="resetSearch"
        />
        <b-button
          v-if="!checkAddButton && !isLoading"
          variant="success"
          class="mr-1"
          @click="openBankAccountModal(false)"
        > <feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />ADD
        </b-button>
      </div>

      <b-card
        no-body
        class="mb-2"
      >

        <filter-slot

          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters"
          @reload="$refs['bankAccountList'].refresh()"
        >

          <b-table
            id="bankAccountList"
            slot="table"
            ref="bankAccountList"
            class="m-1"
            sticky-header="70vh"
            no-provider-filtering
            :busy.sync="isBusy"
            :items="search"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            show-empty
            small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template v-slot:cell(initial_balance)="data">

              <div
                class="d-flex flex-column  justify-content-center align-items-start"
              >
                <span>
                  {{
                    data.item.text_currency
                  }} {{ formatPrice(data.item.balance) }}

                </span></div>

            </template>
            <template v-slot:cell(current_balance)="data">

              <div
                class="d-flex flex-column  justify-content-center align-items-start"
              >
                <span>
                  {{
                    data.item.text_currency
                  }} {{
                    formatPrice(calculateNewBalance(data.item.balance, data.item.total_incomes, data.item.total_expenses))
                  }}
                </span>

              </div>

            </template>
            <template v-slot:cell(currency)="data">

              <div
                class="d-flex flex-column  justify-content-center align-items-start"
              >
                <span>
                  {{ data.item.text_currency }}
                </span>

              </div>

            </template>
            <template v-slot:cell(created_by)="data">

              <div
                class="d-flex  flex-column  justify-content-center align-items-start"
              >
                <span>

                  <p> {{ data.item.created_by }}</p>
                  <p>  {{ data.item.created_at | myGlobalWithHour }}</p>
                </span>

              </div>

            </template>
            <template v-slot:cell(updated_by)="data">

              <div
                class="d-flex  flex-column  justify-content-center align-items-start"
              >
                <span>

                  <p>  {{ data.item.updated_by }}</p>
                  <p>  {{ data.item.updated_at | myGlobalWithHour }}</p>
                </span>

              </div>

            </template>
            <template v-slot:cell(actions)="data">
              <div
                class="d-flex   justify-content-center align-items-start "
              >
                <feather-icon
                  title="EDIT"
                  icon="EditIcon"
                  size="20"
                  class="cursor-pointer text-warning"
                  @click="openBankAccountModal(true,data.item)"
                />
                <feather-icon
                  title="DELETED"
                  icon="Trash2Icon"
                  size="20"
                  class="cursor-pointer ml-1  text-danger"
                  @click="openDeleteBankAccount(data.item)"
                />
              </div>
            </template>
          </b-table>

        </filter-slot>

      </b-card>

    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue'
import dataFields from '@/views/management/views/companies-old/dashboard/bank-accounts-module/fields.data'
import dataFilters from '@/views/management/views/companies-old/dashboard/bank-accounts-module/filters.data'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import CreateBankAccount
from '@/views/management/views/companies-old/dashboard/bank-accounts-module/modals/CreateBankAccount.vue'

export default {
  components: {
    FilterSlot,
    CreateBankAccount,

  },

  data() {
    return {
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      bankAccountModal: false,
      edit: false,
      companyInformation: [],
      checkAddButton: false,
      isLoading: true,
      dataBankAccount: {},
      itemsValidation: [],
    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },
  async created() {

  },
  methods: {

    closeBankAccountModal() {
      this.bankAccountModal = false
    },
    openBankAccountModal(edit, item) {
      this.edit = edit
      this.dataBankAccount = item
      this.bankAccountModal = true
    },
    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.bankAccountList.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.bankAccountList.refresh()
    },

    // eslint-disable-next-line consistent-return
    async openDeleteBankAccount(data) {
      const params = {
        idbank_account: data.id,
        user_id: this.currentUser.user_id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await CompaniesService.deleteBankAccounts(params)

          this.$refs.bankAccountList.refresh()

          this.showSuccessSwal('Bank Account has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async search(ctx) {
      try {
        if (this.companyInformation === []) {
          this.isLoading = true
        }
        const params = {
          perPage: this.paginate.perPage,
          orderby: 1,
          order: 'asc',
          from: this.filter[0].model,
          campo: this.filterPrincipal.model,
          to: this.filter[1].model,
          idcompany: this.$route.params.id,

        }
        await this.getCompany()
        const data = await CompaniesService.searchBankAccountAll(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        await this.searchValidation()
        this.isLoading = false
        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async searchValidation() {
      try {
        if (this.companyInformation === []) {
          this.isLoading = true
        }
        const params = {
          perPage: this.paginate.perPage,
          orderby: 1,
          order: 'asc',
          from: null,
          campo: null,
          to: null,
          idcompany: this.$route.params.id,

        }
        await this.getCompany()
        const data = await CompaniesService.searchBankAccountAll(params, 1)

        this.itemsValidation = data.data.data
        // Must return an array of items or an empty array if an error occurred

        if (this.companyInformation === 'US' && this.itemsValidation.length === 1) {
          this.checkAddButton = true
        } else if (this.companyInformation === 'PE' && this.itemsValidation.length === 2) {
          this.checkAddButton = true
        } else {
          this.checkAddButton = false
        }

        return this.itemsValidation
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    calculateNewBalance(balance, incomes, expenses) {
      return (Number(balance) + Number(incomes)) - Number(expenses)
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getCompany() {
      try {
        const params = {
          company_id: (this.$route.params.id).toString(),
        }
        const data = await CompaniesService.getCompanyById(params)
        this.companyInformation = data.data[0].country
        return this.companyInformation
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
