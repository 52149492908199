export default [
  {
    key: 'bank_name',
    sortable: false,
    label: 'Bank Name',
  },
  {
    key: 'number_account',
    sortable: false,
    label: 'Number account',
  },
  {
    key: 'initial_balance',
    sortable: false,
    label: 'Initial Balance',
  },
  {
    key: 'current_balance',
    sortable: false,
    label: 'Current Balance',
  },

  {
    key: 'currency',
    sortable: false,
    label: 'Currency',
  },
  {
    key: 'user',
    sortable: false,
    label: 'User',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'Created By',
  },

  {
    key: 'updated_by',
    sortable: false,
    label: 'Updated By',
  },

  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },

]
